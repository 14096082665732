import {
    Text,
    Grid,
    Space,
    Center,
} from "@mantine/core";
import { CustomLink } from "../CustomLink";
import { BlogEntry } from "./BlogEntry";
import { useEffect, useState } from "react";
import ContentApi from "@tryghost/content-api";

export type PostProps = {
    id: string;
    published_at: string;
    feature_image: string;
    title: string;
    excerpt: string;
    reading_time: number;
    url: string;
};

export const PostsInicio = (): JSX.Element => {
    const [posts, setPosts] = useState<PostProps[]>([]);

    useEffect((): void => {
      const api = new ContentApi({
        url: "https://blog.elsa.la",
        key: "1ac06945d39473b608fe76f670",
        version: "v3.0",
      });
  
      const getPosts = async () => {
        const posts = await api.posts.browse();
        posts.splice(3);
        setPosts(posts);
      };
  
      getPosts();
    }, []);

    return (
        <>
            {posts.length > 0 && (
                <Grid.Col span={12} mt="xl">
                    <Text size="lg">
                        ¿Ya leíste nuestro blog{" "}
                        <CustomLink
                            to="https://blog.elsa.la"
                            target="_blank"
                            fontSize=""
                        >
                            #MultiplicaIgualdad
                        </CustomLink>
                        ? Aquí los últimos artículos:
                    </Text>
                    <Space h="md" />
                    <Grid>
                        {posts.map((post: PostProps) => (
                            <Grid.Col key={post.id} span={12} lg={4}>
                                <BlogEntry post={post} />
                            </Grid.Col>
                        ))}
                    </Grid>
                    <Center mt="md">
                        <CustomLink
                            to="https://blog.elsa.la"
                            target="_blank"
                            fontSize="14"
                        >
                            Ver más ➔
                        </CustomLink>
                    </Center>
                </Grid.Col>
            )}
        </>
    );
}
