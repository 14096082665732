import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";

export const BenchmarkConceptoChart = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  return (
    <ResultadosCard
      title="Concepto de hostigamiento o acoso sexual laboral"
      description="Porcentaje que cree que estas características son necesarias para que haya acoso sexual."
    >
      <BarChart
        categories={[
          ["Sí debe mostrar", "rechazo expreso"],
          ["Sí debe suceder", "más de una vez"],
          ["Sí debe tener", "intención de acosar"],
          ["Sí debe venir", "de un/a jefe/a"],
        ]}
        seriesData={[
          [
            generalBenchmark.benchmarks.RB_0006,
            generalBenchmark.benchmarks.RB_0005,
            generalBenchmark.benchmarks.RB_0007,
            generalBenchmark.benchmarks.RB_0008,
          ],
        ]}
        seriesNames={["Benchmark"]}
        height={400}
        width={600}
      />
    </ResultadosCard>
  );
};
