import { Text, Flex, Title } from "@mantine/core";
import {
  PuntajeBonusPenalidad,
  ResultadosCard,
  Tacometro,
} from "../../Resultados";
import { GeneralBenchmarkProps } from "../GeneralBenchmarkProps";
import { roundToOneDecimal } from "../../../utils";
import { TextWithInfoTooltip } from "../../Miscellaneous";

export const BenchmarkSubindice = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  return (
    <ResultadosCard>
      <Flex
        justify="space-around"
        align="center"
        gap="xl"
        direction={{ base: "column", md: "row" }}
      >
        <Flex direction="column" align="center" gap="xs">
          <Title order={5}>Subíndices</Title>
          <Flex justify="space-around" gap={30} align="center">
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0002 * 100
                )}
              />
              <TextWithInfoTooltip
                text="Sistema de Prevención"
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      Sistema de Prevención
                    </Title>
                    <Text align="justify">
                      Puntaje obtenido de la encuesta organizacional de las
                      empresas ELSA. Este subíndice evalúa el nivel de
                      implementación del sistema de prevención de las
                      organizaciones.
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0003 * 100
                )}
              />
              <TextWithInfoTooltip
                text="Conocimiento"
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      Conocimiento
                    </Title>
                    <Text align="justify">
                      Puntaje obtenido de la encuesta al personal de las
                      empresas ELSA. Este subíndice evalúa el nivel de
                      conocimiento por parte de las personas encuestadas sobre
                      el sistema de prevención en la organización.
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0004 * 100
                )}
              />
              <TextWithInfoTooltip
                text="Tolerancia"
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      Tolerancia
                    </Title>
                    <Text align="justify">
                      Puntaje obtenido de la encuesta al personal de las
                      empresas ELSA. Este subíndice evalúa las percepciones de
                      las personas encuestadas acerca de situaciones de acoso
                      sexual laboral.
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
            <Flex direction="column" align="center">
              <Tacometro
                value={roundToOneDecimal(
                  generalBenchmark.benchmarks.IRB_0005 * 100
                )}
              />
              <TextWithInfoTooltip
                text="Clima Organizacional"
                tooltipText={
                  <>
                    <Title order={5} sx={{ marginBottom: "10px" }}>
                      Clima Organizacional
                    </Title>
                    <Text align="justify">
                      Puntaje obtenido de la encuesta al personal de las
                      empresas ELSA. Este subíndice evalúa la percepción de las
                      personas en relación al manejo de los casos en la
                      organización.
                    </Text>
                  </>
                }
                width={300}
                fw={400}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" align="center" gap="sm">
          <Title order={5}>Bonus</Title>
          <PuntajeBonusPenalidad
            value={roundToOneDecimal(
              generalBenchmark.benchmarks.IRB_0006 * 100
            )}
            isBonus
          />
          <TextWithInfoTooltip
            text="Testigos"
            tooltipText={
              <>
                <Title order={5} sx={{ marginBottom: "10px" }}>
                  Testigos
                </Title>
                <Text align="justify">
                  Los puntos adicionales se otorgan de acuerdo a las acciones
                  adoptadas por las personas que reconocen haber sido testigos.
                  Estos puntos buscan promover una participación activa de
                  terceros en situaciones de acoso sexual laboral.
                </Text>
              </>
            }
            width={300}
            fw={400}
          />
        </Flex>

        <Flex direction="column" align="center" gap="sm">
          <Title order={5}>Penalidad</Title>
          <PuntajeBonusPenalidad
            value={roundToOneDecimal(
              generalBenchmark.benchmarks.IRB_0007 * 100
            )}
            isPenalty
          />
          <TextWithInfoTooltip
            text="Mitos y Sesgos"
            tooltipText={
              <>
                <Title order={5} sx={{ marginBottom: "10px" }}>
                  Mitos y Sesgos
                </Title>
                <Text align="justify">
                  Los puntos en contra se descuentan por la presencia de mitos y
                  sesgos inconscientes en la resolución de casos por personal
                  clave, como líderes o comités de intervención. Se busca
                  desincentivar estos mitos y sesgos, ya que es fundamental que
                  quienes derivan, acompañan, investigan y resuelven los casos
                  los reduzcan.
                </Text>
              </>
            }
            width={300}
            fw={400}
          />
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
