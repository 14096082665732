import { useEffect, useState } from "react";
import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  ResultadosCard,
  BenchmarkConceptoChart,
  BenchmarkToleranciaHeatmap,
  BenchmarkSistemaPrevencionChart,
  BenchmarkAcosoTecnicoYDeclaradoPrevalencia,
  BenchmarkPrincipalesManifestaciones,
  TitleCard,
  SectionTitle,
  BenchmarkPercepcionPositiva,
  BenchmarkIndice,
  BenchmarkSubindice,
  BenchmarkDistribucionPieChartCard,
  BenchmarkPrevalenciaPerfilPersonal,
  BenchmarkReaccionesCard,
  BenchmarkBarrerasCard,
  BenchmarkPerfilAcosador,
  BenchmarkTestigos,
  BenchmarkReaccionesTestigos,
  BenchmarkIncertidumbreCard,
  BenchmarkResolucionDeCasosCard,
  BenchmarkMitosYSesgos,
} from "../../components";
import { useAuth } from "../../context";
import { createStyles, Text, Flex, Tabs } from "@mantine/core";
import { TGeneralBenchmark } from "../../types";
import { clientService } from "../../api";

const useStyles = createStyles((theme) => ({
  title: {
    position: "sticky",
    zIndex: 1000,
    backgroundColor: theme.colors.gray[0],
    top: 0,
  },
  container: {
    maxWidth: 700,
  },
  conocimientoContainer: {
    maxWidth: 1400,
    margin: "0 auto",
  },
  tabs: {
    position: "sticky",
    zIndex: 1000,
    backgroundColor: theme.colors.gray[0],
    top: 80,
  },
}));

const responsiveBarStylesPrevalencia = [
  {
    breakpoint: 768,
    options: {
      chart: {
        width: 300,
      },
    },
  },
];

export const BenchmarkPage = () => {
  const { user } = useAuth();
  const { classes } = useStyles();
  const [generalBenchmark, setGeneralBenchmark] =
    useState<TGeneralBenchmark | null>(null!);

  const breadcrumbs = () => (
    <div className={classes.title}>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: "Benchmark ELSA" }]}
      />
    </div>
  );

  const loader = () => (
    <GenderLabAppShell>
      {breadcrumbs()}
      <GenderLabLoader />
    </GenderLabAppShell>
  );

  useEffect(() => {
    const getGeneralBenchmark = async () => {
      setGeneralBenchmark((await clientService.benchmark.general.get()).data);
    };

    getGeneralBenchmark();
  }, [user]);

  if (!user || !generalBenchmark) {
    return loader();
  }

  return (
    <GenderLabAppShell>
      {breadcrumbs()}
      <Flex direction="column" gap="xl" sx={{ marginBottom: 20 }}>
        <Text color="dimmed" sx={{ textAlign: "justify" }}>
          En esta sección de benchmark encontrarás la data agregada de todas las
          empresas con medición ELSA. De este modo podrás beneficiarte de forma
          exclusiva de métricas que muestran cómo se comportan los datos de las
          diferentes organizaciones que se han medido en la plataforma. La data
          sobre la que se han realizado los cálculos fue recogida durante todo
          el año {generalBenchmark.year - 1}.
        </Text>
      </Flex>
      <Tabs defaultValue="indice">
        <div className={classes.tabs}>
          <Tabs.List grow>
            <Tabs.Tab value="indice">Índice</Tabs.Tab>
            <Tabs.Tab value="muestra">Muestra</Tabs.Tab>
            <Tabs.Tab value="sistema-prevencion">
              Sistema de prevención
            </Tabs.Tab>
            <Tabs.Tab value="tolerancia">Tolerancia</Tabs.Tab>
            <Tabs.Tab value="prevalencia">Prevalencia</Tabs.Tab>
            <Tabs.Tab value="testigos">Testigos</Tabs.Tab>
            <Tabs.Tab value="clima-orga">Clima organizacional</Tabs.Tab>
            <Tabs.Tab value="liderazgo">Liderazgo</Tabs.Tab>
          </Tabs.List>
        </div>

        <Tabs.Panel value="indice" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Índice ELSA"
              description="El índice ELSA es una metodología basada en los datos de una medición. Este otorga puntajes a las organizaciones según su nivel de prevención de acoso sexual laboral. A continuación puedes ver los resultados agregados en el Índice ELSA de todas las organizaciones que se midieron."
            />
            <BenchmarkIndice generalBenchmark={generalBenchmark} />
            <Flex gap="xs">
              <BenchmarkSubindice generalBenchmark={generalBenchmark} />
            </Flex>
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="muestra" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Muestra"
              description="Composición de la muestra del benchmark según perfil personal de las personas encuestadas de las empresas ELSA."
            />
            <Flex gap="md" direction={{ base: "column", md: "row" }}>
              <BenchmarkDistribucionPieChartCard
                title="Género"
                labels={["Hombres", "Mujeres", "No binario"]}
                series={[
                  generalBenchmark.benchmarks.RB_0186,
                  generalBenchmark.benchmarks.RB_0187,
                  generalBenchmark.benchmarks.RB_0188,
                ]}
              />
              <BenchmarkDistribucionPieChartCard
                title="Pertenencia a la comunidad LGBTIQ+"
                labels={[
                  "Comunidad LGBTIQ+",
                  "No comunidad LGBTIQ+",
                  "Prefiero no decir",
                ]}
                series={[
                  generalBenchmark.benchmarks.RB_0189,
                  generalBenchmark.benchmarks.RB_0190,
                  generalBenchmark.benchmarks.RB_0191,
                ]}
              />
            </Flex>
            <Flex gap="md" direction={{ base: "column", md: "row" }}>
              <BenchmarkDistribucionPieChartCard
                title="Rango Etario"
                labels={[
                  "18 - 29 años",
                  "30 - 49 años",
                  "50 - 59 años",
                  "60 años a más",
                  "Prefiero no responder",
                ]}
                series={[
                  generalBenchmark.benchmarks.RB_0192,
                  generalBenchmark.benchmarks.RB_0193,
                  generalBenchmark.benchmarks.RB_0194,
                  generalBenchmark.benchmarks.RB_0195,
                  generalBenchmark.benchmarks.RB_0196,
                ]}
              />
              <BenchmarkDistribucionPieChartCard
                title="Autoidentificación étnico-racial"
                labels={[
                  "Asiático",
                  "Mestizo",
                  "Indígena",
                  "Afrodescendiente",
                  "Blanco",
                  "Otro",
                  "Prefiero no responder",
                ]}
                series={[
                  generalBenchmark.benchmarks.RB_0197,
                  generalBenchmark.benchmarks.RB_0198,
                  generalBenchmark.benchmarks.RB_0199,
                  generalBenchmark.benchmarks.RB_0200,
                  generalBenchmark.benchmarks.RB_0201,
                  generalBenchmark.benchmarks.RB_0202,
                  generalBenchmark.benchmarks.RB_0203,
                ]}
              />
            </Flex>
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="sistema-prevencion" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Sistema de prevención"
              description="Conocimiento por parte de las personas encuestadas de las empresas ELSA sobre sus sistemas de prevención."
            />
            <BenchmarkSistemaPrevencionChart
              generalBenchmark={generalBenchmark}
              classes={classes}
            />
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="tolerancia" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Tolerancia"
              description="Percepciones de las personas encuestadas en las empresas ELSA acerca de situaciones de hostigamiento o acoso sexual laboral."
            />
            <BenchmarkToleranciaHeatmap generalBenchmark={generalBenchmark} />
            <BenchmarkConceptoChart generalBenchmark={generalBenchmark} />
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="prevalencia" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Prevalencia"
              description="Nivel de incidencia, reacciones e impacto del hostigamiento o acoso sexual laboral en las empresas ELSA."
            />
            <BenchmarkAcosoTecnicoYDeclaradoPrevalencia
              technicalHarassmentPrevalence={
                generalBenchmark.benchmarks.RB_0040
              }
              declaredHarassmentPrevalence={generalBenchmark.benchmarks.RB_0039}
            />
            <BenchmarkPrevalenciaPerfilPersonal
              generalBenchmark={generalBenchmark}
              responsiveStyle={responsiveBarStylesPrevalencia}
            />
            <BenchmarkPrincipalesManifestaciones
              generalBenchmark={generalBenchmark}
            />

            <TitleCard
              title="Métricas asociadas a Acoso u Hostigamiento Sexual Laboral Declarado"
              description="Calculadas en base a las personas que reconocieron haber vivido acoso sexual laboral y las acciones que tomaron, costos asociados, barreras para la denuncia y perfil de las personas que realizaron estas conductas."
            />
            <BenchmarkReaccionesCard
              generalBenchmark={generalBenchmark}
              responsiveStyle={responsiveBarStylesPrevalencia}
            />
            <BenchmarkBarrerasCard generalBenchmark={generalBenchmark} />
            <BenchmarkPerfilAcosador
              generalBenchmark={generalBenchmark}
              responsiveStyle={responsiveBarStylesPrevalencia}
            />
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="testigos" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Testigos"
              description="Personas que han sido testigos de situaciones de hostigamiento o acoso sexual laboral en las empresas ELSA."
            />
            <BenchmarkTestigos generalBenchmark={generalBenchmark} />
            <BenchmarkReaccionesTestigos generalBenchmark={generalBenchmark} />
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="clima-orga" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Clima organizacional"
              description="Percepciones de las personas encuestadas sobre el manejo de casos de hostigamiento o acoso sexual laboral en sus respectivas empresas ELSA."
            />
            <BenchmarkIncertidumbreCard generalBenchmark={generalBenchmark} />
            <BenchmarkPercepcionPositiva generalBenchmark={generalBenchmark} />
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value="liderazgo" pt="md">
          <Flex direction="column" gap="md">
            <SectionTitle
              title="Liderazgo"
              description="Correcta resolución de casos de hostigamiento o acoso sexual laboral, y presencia de mitos y sesgos en la resolución de casos por parte del personal de liderazgo, Recursos Humanos y Comités de Intervención de las empresas ELSA."
            />
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-evenly"
              gap="md"
            >
              <ResultadosCard title="¿De qué trata esta sección?">
                <Text align="justify">
                  La resolución de casos de hostigamiento sexual laboral nos
                  permite identificar si las personas cuentan con las
                  herramientas analíticas para resolverlos. En esta sección
                  encontramos 4 casos de situaciones típicas de hostigamiento o
                  acoso sexual laboral para que las personas respondan 4
                  preguntas que nos permitirán identificar dos aspectos
                  relevantes: 1) Si identifican situaciones de hostigamiento o
                  acoso sexual laboral; 2) El impacto de sesgos inconscientes a
                  la hora de resolver casos.
                </Text>
              </ResultadosCard>
              <ResultadosCard title="¿Quiénes llegaron a esta sección?">
                <Text align="justify">
                  Esta sección le aparece a líderes/as de la organización
                  (Niveles 1,2 y 3), al personal de Recursos Humanos/Gestión del
                  Talento, y a las personas integrantes del Comité de
                  Intervención, de Convivencia, de Ética, o afín. Al ser
                  personas que se encuentran vinculadas con los casos que puedan
                  ocurrir dentro de la organización, es importante medir si
                  están preparadas para abordarlos, bien sea desde el liderazgo,
                  o desde las instancias que reciben y resuelven los casos de
                  hostigamiento o acoso sexual.
                </Text>
              </ResultadosCard>
            </Flex>
            <Flex gap="md" direction={{ base: "column", md: "row" }}>
              <BenchmarkResolucionDeCasosCard
                generalBenchmark={generalBenchmark}
              />
              <BenchmarkMitosYSesgos generalBenchmark={generalBenchmark} />
            </Flex>
          </Flex>
        </Tabs.Panel>
      </Tabs>
    </GenderLabAppShell>
  );
};
