import { Grid, Text } from "@mantine/core";
import { parsePercentage } from "../../utils";
import { Heatmap } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";

export const BenchmarkIncertidumbreCard = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    return (
        <ResultadosCard
            title="Incertidumbres sobre el manejo de casos"
            description="Porcentaje que está totalmente de acuerdo o de acuerdo con las siguientes afirmaciones."
        >
            <Heatmap
                categories={["I1", "I2", "I3", "I4"]}
                data={[
                    {
                    name: "Total",
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0029),
                        parsePercentage(generalBenchmark.benchmarks.RB_0030),
                        parsePercentage(generalBenchmark.benchmarks.RB_0031),
                        parsePercentage(generalBenchmark.benchmarks.RB_0032),
                    ],
                    },
                    {
                    name: "No binario",
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0180),
                        parsePercentage(generalBenchmark.benchmarks.RB_0181),
                        parsePercentage(generalBenchmark.benchmarks.RB_0182),
                        parsePercentage(generalBenchmark.benchmarks.RB_0183),
                    ],
                    },
                    {
                    name: "Femenino",
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0174),
                        parsePercentage(generalBenchmark.benchmarks.RB_0175),
                        parsePercentage(generalBenchmark.benchmarks.RB_0176),
                        parsePercentage(generalBenchmark.benchmarks.RB_0177),
                    ],
                    },
                    {
                    name: "Masculino",
                    data: [
                        parsePercentage(generalBenchmark.benchmarks.RB_0168),
                        parsePercentage(generalBenchmark.benchmarks.RB_0169),
                        parsePercentage(generalBenchmark.benchmarks.RB_0170),
                        parsePercentage(generalBenchmark.benchmarks.RB_0171),
                    ],
                    },
                ]}
            />
            <Grid gutter="md">
                <Grid.Col span={3}>
                    <Text color="dimmed" size="xs">
                        I1: Si denuncio un caso de hostigamiento o acoso sexual
                        laboral, me podría generar consecuencias en mi trabajo.
                    </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                    <Text color="dimmed" size="xs">
                        I2: Un caso de hostigamiento o acoso sexual en el que se
                        acusa a una persona que no pertenece a la plana gerencial no
                        sería investigado adecuadamente en mi trabajo.{" "}
                    </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                    <Text color="dimmed" size="xs">
                        I3: Un caso de hostigamiento o acoso sexual en el que se
                        acusa a una persona que pertenece a la plana gerencial no
                        sería investigado adecuadamente en mi trabajo.
                    </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                    <Text color="dimmed" size="xs">
                        I4: Las personas que hostigan o acosan a otras/os
                        normalmente se salen con la suya (por ejemplo, no son
                        sancionadas).
                    </Text>
                </Grid.Col>
            </Grid>
        </ResultadosCard>
    );
}