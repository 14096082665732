import { Flex } from "@mantine/core";
import { PorcentajeCardSmall, ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { colorAzul1 } from "../../utils";

export const BenchmarkTestigos = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    return (
        <ResultadosCard
            title="Personas que fueron testigos"
            description="Porcentaje de personas encuestadas que han sido testigo de hostigamiento o acoso sexual laboral en los últimos 12 meses."
        >
            <Flex
                direction={{ base: "column", md: "row" }}
                gap="sm"
                justify="space-evenly"
                align="center"
            >
                <PorcentajeCardSmall
                    description="De personas fueron testigos de situaciones típicas de hostigamiento o acoso sexual laboral"
                    percentage={generalBenchmark.benchmarks.RB_0167}
                    color={colorAzul1}
                />
            </Flex>
        </ResultadosCard>
    );
}