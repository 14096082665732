import { Flex, Sx, Text, createStyles } from "@mantine/core";
import { parsePercentage } from "../../utils";

type Props = {
  percentage: number;
  description: string;
  sx?: Sx | (Sx | undefined)[] | undefined;
};

const useStyles = createStyles((theme) => ({
  cardContainer: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: "10px",
  },
  cardPorcentaje: {
    minWidth: 80,
    width: 65,
    fontSize: 20,
    fontWeight: 600,
  },
  cardTusResultados: {
    backgroundColor: theme.colors.gray[1],
    color: theme.colors.gray[7],
  },
  cardBordeRedondo: {
    ":last-child": {
      borderRadius: "0 10px 10px 0",
    },
  },
}));

export const BenchmarkPorcentajeCard = ({
  percentage,
  description,
  sx,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Flex gap="xs" className={classes.cardContainer}>
      <Text px="xs" size="sm" py="xs" sx={{ flexGrow: 1 }}>
        {description}
      </Text>
      <Flex
        className={`${classes.cardPorcentaje} ${classes.cardTusResultados} ${classes.cardBordeRedondo}`}
        justify="center"
        align="center"
        sx={sx}
      >
        {parsePercentage(percentage)}%
      </Flex>
    </Flex>
  );
};
