import {
  Card,
  Text,
  Button,
  Stack,
  Image,
  Group,
  Badge,
  Avatar,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

type FeatureCardProps = {
  title: string;
  description: string;
  redirectTo: string;
  badgeText?: string;
  badgeColor?: string;
  buttonText: string;
  imageUrl: string;
};

export const FeatureCard = ({
  title,
  description,
  redirectTo,
  badgeText,
  badgeColor,
  buttonText,
  imageUrl,
}: FeatureCardProps): JSX.Element => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(redirectTo);
  };

  return (
    <Card
      shadow="sm"
      p="lg"
      radius="sm"
      withBorder
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {/* <Card.Section>
        <Image src={imageUrl} height={120} alt="Feature image" />
      </Card.Section> */}

      <Stack style={{ flexGrow: 1 }}>
        <Group position="apart">
          <Text weight={500} fz={18}>
            {title}
          </Text>
          {badgeText && (
            <Badge color={badgeColor} variant="light">
              {badgeText}
            </Badge>
          )}
        </Group>

        <Text size="sm" color="dimmed" align="justify" style={{ flexGrow: 1 }}>
          {description}
        </Text>

        <Button
          variant="light"
          color="blue"
          fullWidth
          radius="md"
          onClick={handleRedirect}
        >
          {buttonText}
        </Button>
      </Stack>
    </Card>
  );
};

export default FeatureCard;
