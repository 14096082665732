import { Flex } from "@mantine/core";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithResponsiveStyleProps } from "./GeneralBenchmarkProps";
import { BarChart } from "../Charts";
import { colorAzul1 } from "../../utils";

export const BenchmarkPerfilAcosador = ({
    generalBenchmark,
    responsiveStyle,
}: GeneralBenchmarkWithResponsiveStyleProps): JSX.Element => {
    return (
        <ResultadosCard
            title="¿Quiénes realizan estas conductas?"
            description="Perfil de las personas que realizaron las situaciones de acoso sexual según las personas que reconocen expresamente haberlas vivido."
        >
            <Flex
                direction={{ base: "column", xl: "row" }}
                gap="md"
                justify="space-evenly"
                align="center"
            >
                <BarChart
                    width={450}
                    colors={[colorAzul1]}
                    categories={["Hombres", "Mujeres"]}
                    responsive={responsiveStyle}
                    title="Género de la persona que hostiga o acosa"
                    seriesData={[
                        [
                        generalBenchmark.benchmarks.RB_0160,
                        generalBenchmark.benchmarks.RB_0161,
                        ],
                    ]}
                />
                <BarChart
                    width={500}
                    categories={[
                        "Colega o compañero/a",
                        "Jefe/a",
                        "Subordinado/a",
                        ["Trabajador/a de otra", "organización"],
                        "Cliente o usuario",
                    ]}
                    colors={[colorAzul1]}
                    responsive={responsiveStyle}
                    title="¿Qué relación tenía con la persona que acosaba?"
                    seriesData={[
                        [
                        generalBenchmark.benchmarks.RB_0162,
                        generalBenchmark.benchmarks.RB_0163,
                        generalBenchmark.benchmarks.RB_0164,
                        generalBenchmark.benchmarks.RB_0165,
                        generalBenchmark.benchmarks.RB_0166,
                        ],
                    ]}
                />
            </Flex>
        </ResultadosCard>
    );
}