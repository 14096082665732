import { colorAzul1 } from "../../utils";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";

export const BenchmarkResolucionDeCasosCard = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    return (
        <ResultadosCard title="Resolución de casos">
            <BarChart
                categories={[
                    ["Hostigamiento", "homofóbico"],
                    ["Ambiente de", "trabajo hostil"],
                    ["Chantaje sexual"],
                    ["Hostigamiento", "horizontal"],
                ]}
                seriesNames={["Porcentaje (%)"]}
                seriesData={[
                    [
                        generalBenchmark.benchmarks.RB_0035,
                        generalBenchmark.benchmarks.RB_0036,
                        generalBenchmark.benchmarks.RB_0037,
                        generalBenchmark.benchmarks.RB_0038,
                    ],
                ]}
                colors={[colorAzul1]}
                width={500}
            />
        </ResultadosCard>
    );
};