import { Center } from "@mantine/core";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithClassesProps } from "./GeneralBenchmarkProps";
import { colorCeleste1 } from "../../utils";

export const BenchmarkSistemaPrevencionChart = ({
  generalBenchmark,
  classes,
}: GeneralBenchmarkWithClassesProps): JSX.Element => {
  return (
    <ResultadosCard
      title="Conocimiento del sistema de prevención"
      description="Porcentaje de personas encuestadas de las empresas ELSA que conocen los distintos elementos de sus sistemas de prevención."
    >
      <div className={classes.conocimientoContainer}>
        <Center>
          <BarChart
            categories={[
              ["Conoce y ha", "revisado la política"],
              ["Recibió capacitación", "al menos una vez"],
              ["Conoce y sabe cómo", "acceder al", "canal de denuncias"],
              ["Conoce el procedimiento", "de investigación"],
            ]}
            seriesData={[
              [
                generalBenchmark.benchmarks.RB_0001,
                generalBenchmark.benchmarks.RB_0002,
                generalBenchmark.benchmarks.RB_0003,
                generalBenchmark.benchmarks.RB_0004,
              ],
            ]}
            seriesNames={["Benchmark"]}
            height={400}
            width={600}
            colors={[colorCeleste1]}
          />
        </Center>
      </div>
    </ResultadosCard>
  );
};
