import { Flex, Text } from "@mantine/core";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { BenchmarkPorcentajeCard } from "./BenchmarkPorcentajeCard";

export const BenchmarkReaccionesTestigos = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    return (
        <ResultadosCard
            title="Reacciones frente a estas situaciones"
            description="Porcentaje de testigos que tuvieron las siguientes reacciones."
            alignSelf="flex-start"
        >
            <Flex justify="center">
                <Flex direction="column" gap="xs" maw={600}>
                    <Flex justify="flex-end" gap={10}>
                        <div style={{ width: 80 }}>
                            <Text fz={12} sx={{ fontWeight: 600 }}>
                                Porcentaje (%)
                            </Text>
                        </div>
                    </Flex>

                    {Object.entries(generalBenchmark.benchmarks)
                        .filter(([key]) =>
                            [
                            "RB_0021",
                            "RB_0022",
                            "RB_0023",
                            "RB_0024",
                            "RB_0025",
                            "RB_0026",
                            "RB_0027",
                            "RB_0028",
                            ].includes(key)
                    )
                    .sort(([, a], [, b]) => b - a)
                    .map(([key, percentage]) => {
                        const descriptions: { [key: string]: string } = {
                        RB_0021:
                            "Generó una distracción (por ejemplo, cambió el tema de conversación).",
                        RB_0022:
                            "No intervino porque no supo qué hacer en ese momento.",
                        RB_0023:
                            "Confrontó a la persona que estaba haciendo estas conductas.",
                        RB_0024:
                            "En ese momento no intervino pero luego se acercó a quien incurrió en acoso u hostigamiento.",
                        RB_0025:
                            "En ese momento no intervino pero luego se acercó a la persona para ver cómo estaba.",
                        RB_0026:
                            "Solo guardó un registro de lo sucedido (por ejemplo, guardó los chats).",
                        RB_0027: "No le pareció necesario intervenir.",
                        RB_0028:
                            "Informé de lo sucedido a recursos humanos o a mi jefe/a.",
                        };

                        return (
                            <BenchmarkPorcentajeCard
                                key={key}
                                description={descriptions[key]}
                                percentage={percentage}
                                sx={{
                                    backgroundColor: "#eaddf3",
                                    color: "#6f4dbc",
                                }}
                            />
                        );
                    })}
                </Flex>
            </Flex>
        </ResultadosCard>
    );
}