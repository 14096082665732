import { Flex } from "@mantine/core";
import { BenchmarkBarrerasGeneralesCard } from "./BenchmarkBarrerasGeneralesCard";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";
import { colorRojoClaro1 } from "../../utils";
import { ResultadosCard } from "../Resultados";

export const BenchmarkBarrerasCard = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    return (
        <ResultadosCard
            title="Barreras para la denuncia"
            description="Razones por las que las personas que reconocen expresamente haber vivido acoso sexual no denunciaron lo sucedido."
        >
            <Flex
                direction={{ base: "column", lg: "row" }}
                gap="md"
                justify="center"
                align="center"
            >
                <Flex
                    direction={{ base: "column", lg: "row" }}
                    gap="lg"
                    align="center"
                >
                    <Flex direction="column" gap="xs">
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#f9d6d6", colorRojoClaro1]}
                            title="Miedo por factores endógenos a la organización"
                            subtitles={[
                                "Tenía vergüenza",
                                "No quería ser vista como una persona problemática en el trabajo",
                                "Tenía miedo de perder mi trabajo o de sufrir algún otro tipo de represalia",
                            ]}
                            percentages={[
                            generalBenchmark.benchmarks.RB_0151,
                            generalBenchmark.benchmarks.RB_0152,
                            generalBenchmark.benchmarks.RB_0153,
                            ]}
                        />
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#a4d7d4", "#109089"]}
                            title="Falta de información"
                            subtitles={[
                                "En ese momento no estaba seguro/a de si era un caso de hostigamiento o acoso sexual laboral",
                                "No sabía o no existe un canal de denuncia",
                            ]}
                            percentages={[
                            generalBenchmark.benchmarks.RB_0154,
                            generalBenchmark.benchmarks.RB_0155,
                            ]}
                        />
                    </Flex>
                    <Flex direction="column" gap="xs">
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#add4f0", "#4fa0db"]}
                            title="Factores exógenos a la organización"
                            subtitles={[
                                "Tenía miedo de tener problemas con mi pareja",
                            ]}
                            percentages={[generalBenchmark.benchmarks.RB_0156]}
                        />
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#c2c3c8", "#626161"]}
                            title="Desconfianza en la organización"
                            subtitles={["Pienso que no sirve de nada denunciar"]}
                            percentages={[generalBenchmark.benchmarks.RB_0157]}
                        />
                        <BenchmarkBarrerasGeneralesCard
                            colors={["#dccfe7", "#500f82"]}
                            title="Consideraciones con acosador/a"
                            subtitles={[
                                "Nada, me pareció que no fue de mala intención",
                                "No quería ocasionarle problemas a la persona",
                            ]}
                            percentages={[
                                generalBenchmark.benchmarks.RB_0158,
                                generalBenchmark.benchmarks.RB_0159,
                            ]}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </ResultadosCard>
    );
}