import { Grid, Paper, Title, Text } from "@mantine/core";

export type BienvenidaInicioProps = {
  tradeName: string;
};

export const BienvenidaInicio = ({
  tradeName,
}: BienvenidaInicioProps): JSX.Element => {
  return (
    <Grid.Col span={12} sx={{ marginTop: "1rem" }}>
      <Paper radius="md" withBorder p="md">
        <Title order={3}>¡Hola, {tradeName}!</Title>
        <Text size="md" color="dimmed" mt="sm">
          Te damos la bienvenida a ELSA (Espacios Laborales Sin Acoso). Aquí te
          acompañaremos en el camino a prevenir y tomar acción frente al acoso
          sexual laboral en tu organización.
        </Text>
      </Paper>
    </Grid.Col>
  );
};
