import { Badge, Flex, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { GeneralBenchmarkProps } from "../GeneralBenchmarkProps";
import { indexColors, roundToOneDecimal } from "../../../utils";
import { ResultadosCard, Tacometro } from "../../Resultados";
import { InfoTooltip } from "../../Miscellaneous";

export const BenchmarkIndice = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  const [nivelIndice, setNivelIndice] = useState<{
    color: string;
    nombre: string;
  }>(null!);

  useEffect((): void => {
    const processIndexLevel = (indice: number) => {
      indice = indice * 100;
      if (indice <= 60) {
        setNivelIndice({ color: indexColors.inicial, nombre: "Inicial" });
      } else if (indice <= 70) {
        setNivelIndice({ color: indexColors.intermedio, nombre: "Intermedio" });
      } else if (indice <= 80) {
        setNivelIndice({
          color: indexColors.intermedioAlto,
          nombre: "Intermedio Alto",
        });
      } else if (indice <= 90) {
        setNivelIndice({ color: indexColors.alto, nombre: "Alto" });
      } else {
        setNivelIndice({ color: indexColors.muyAlto, nombre: "Muy Alto" });
      }
    };
    processIndexLevel(generalBenchmark.benchmarks.IRB_0001);
  }, [generalBenchmark]);

  return (
    <ResultadosCard alignSelf="center">
      <Flex direction="column" align="center">
        <Title order={5}>Puntaje agregado de empresas ELSA</Title>
        <Tacometro
          value={roundToOneDecimal(generalBenchmark.benchmarks.IRB_0001 * 100)}
          big
        />
        <Flex direction="column" align="center">
          <Flex align="center" gap={5}>
            <Title order={5}>Nivel</Title>
            <InfoTooltip
              tooltipText={
                <>
                  <li>Inicial (0 - 60 puntos)</li>
                  <li>Intermedio: (61 - 70 puntos)</li>
                  <li>Intermedio Alto (71 - 80)</li>
                  <li>Alto (81 - 90)</li>
                  <li>Muy Alto (91 - 100)</li>
                </>
              }
            />
          </Flex>
          {nivelIndice && (
            <Badge
              sx={{
                backgroundColor: nivelIndice.color,
                color: "white",
              }}
            >
              {nivelIndice.nombre}
            </Badge>
          )}
        </Flex>
      </Flex>
    </ResultadosCard>
  );
};
