import {
    Card,
    Image,
    Space,
    Stack,
    Text,
} from "@mantine/core";
import { CustomLink } from "../CustomLink";
import { PostProps } from "./PostsInicio";

type BlogEntryProps = {
    post: PostProps;
};

export const BlogEntry = ({ post }: BlogEntryProps) => {
    const date = new Date(post.published_at);
    return (
      <Card radius="md" withBorder>
        <Card.Section>
          <Image src={post.feature_image} alt={post.title} height={150} />
        </Card.Section>
        <Space h="sm" />
        <Stack align="center" justify="center" h={140}>
          <CustomLink to={post.url} target="_blank" fontSize={16} ellipsis>
            {post.title}
          </CustomLink>
          <Text size="xs" color="dimmed" className="ellipsis">
            {post.excerpt}
          </Text>
          <Text size="xs" color="dimmed">
            {post.reading_time} min • {date.getDay()}/{date.getMonth()}/
            {date.getFullYear()}
          </Text>
        </Stack>
      </Card>
    );
  };