import { colorRojoClaro1 } from "../../utils";
import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";

export const BenchmarkMitosYSesgos = ({
    generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
    return (
        <ResultadosCard title="Mitos y sesgos">
            <BarChart
                categories={[
                    ["Responsabilidad", "de la víctima"],
                    ["Heterosexualidad", "naturalizada"],
                    ["Exageración"],
                    ["Motivos Ulteriores"],
                    ["Intencionalidad", "Exacerbada"],
                ]}
                seriesNames={["Porcentaje (%)"]}
                seriesData={[
                    [
                        generalBenchmark.benchmarks.RB_0222,
                        generalBenchmark.benchmarks.RB_0223,
                        generalBenchmark.benchmarks.RB_0224,
                        generalBenchmark.benchmarks.RB_0225,
                        generalBenchmark.benchmarks.RB_0226,
                    ],
                ]}
                colors={[colorRojoClaro1]}
                width={500}
            />
        </ResultadosCard>
    );
};