import { Flex } from "@mantine/core";

import { useAuth } from "../../context/AuthContext";
import "./InicioPage.css";
import { tokenService } from "../../api";
import {
  DashboardInicio,
  ClientSelector,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
} from "../../components";

export const InicioPage = () => {
  const { user } = useAuth();
  const access_role = tokenService.getUserFromToken()?.access_role;

  return (
    <GenderLabAppShell>
      {(access_role === "superuser" || access_role === "partner") && (
        <Flex gap="md" align="center">
          <GenderLabBreadcrumbs breadcrumbs={[{ link: "", title: "Inicio" }]} />
          <ClientSelector access_role={access_role} />
        </Flex>
      )}
      {!user ? (
        <GenderLabLoader />
      ) : (
        <DashboardInicio tradeName={user.tradeName} />
      )}
    </GenderLabAppShell>
  );
};
