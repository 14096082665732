import {
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Group,
  Loader,
  Modal,
  Pagination,
  RingProgress,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { AiOutlineDelete } from "react-icons/ai";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { FaSort } from "react-icons/fa";
import { useParams } from "react-router-dom";

import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabLoader,
  ResultadosCard,
} from "../../components";
import { aulaService } from "../../api/services";
import { TActiveCourse, TActiveCourseEnrollmentPage } from "../../types";
import { formatDate, validateStringField } from "../../utils";
import { useAuth } from "../../context";
import { ZIndex } from "../../utils/zIndex";

const useStyles = createStyles((theme) => ({
  alumnosTHead: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  showOnMedium: {
    display: "none",
    "@media (max-width: 1024px)": {
      display: "block",
    },
  },
  hideOnMedium: {
    "@media (max-width: 1024px)": {
      display: "none",
    },
  },
  hideOnSmall: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
}));

export const CursoCompradoPage = () => {
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      document_id: "",
      workplace: "",
      area: "",
      job: "",
    },

    validate: {
      name: validateStringField,
      email: validateStringField,
    },
  });

  const [opened, { close, open }] = useDisclosure(false);

  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState<string | undefined>(undefined);
  const [currentSortDirection, setCurrentSortDirection] = useState<
    string | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const [courseData, setCourseData] = useState<TActiveCourse>(null!);
  const [enrollmentsPage, setEnrollmentsPage] =
    useState<TActiveCourseEnrollmentPage>(null!);

  const [sortDirection, setSortDirection] = useState({
    student__name: "desc",
    student__email: "desc",
    percentage_completed: "desc",
    completed_at: "desc",
    student__workplace: "desc",
    student__area: "desc",
    student__job: "desc",
    student__document_id: "desc",
  });
  const [activePage, setPage] = useState(1);

  const [courseProgress, setCourseProgress] = useState(0);
  const pageSize = 25;

  const { classes } = useStyles();

  const { code: courseId } = useParams();

  const { user } = useAuth();

  const getEnrollmentsData = async (
    sortField?: string,
    sortDirection?: string
  ) => {
    if (courseId) {
      const response = await aulaService.getEnrollmentsByCourseId(
        parseInt(courseId),
        activePage,
        pageSize,
        query,
        sortField,
        sortDirection
      );
      const enrollmentsPageData: TActiveCourseEnrollmentPage = response.data;
      setEnrollmentsPage(enrollmentsPageData);
    }
  };

  const handleSort = (
    sortField:
      | "student__name"
      | "student__email"
      | "percentage_completed"
      | "completed_at"
      | "student__workplace"
      | "student__area"
      | "student__job"
      | "student__document_id"
  ) => {
    setPage(1);
    const currentSortDirection = sortDirection[sortField];
    setSortField(sortField);
    setCurrentSortDirection(currentSortDirection);

    if (sortDirection[sortField] === "asc") {
      setSortDirection((prevState) => ({
        ...prevState,
        [sortField]: "desc",
      }));
    } else {
      setSortDirection((prevState) => ({
        ...prevState,
        [sortField]: "asc",
      }));
    }
    getEnrollmentsData(sortField, currentSortDirection);
  };

  useEffect(() => {
    const getPageData = async () => {
      if (courseId) {
        getEnrollmentsData(sortField, currentSortDirection);
      }
    };

    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    const getPageData = async () => {
      setPage(1);
      if (courseId) {
        getEnrollmentsData(sortField, currentSortDirection);
      }
    };

    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const getCourseData = async () => {
      if (courseId) {
        const response = await aulaService.getActiveCourseById(
          parseInt(courseId)
        );
        const courseData: TActiveCourse = response.data;
        setCourseData(courseData);
      }
    };

    getCourseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (courseData) {
      setCourseProgress(
        Math.round(
          (courseData.progress.completed / courseData.progress.total) * 100
        )
      );
    }
  }, [courseData]);

  const downloadStudents = async () => {
    if (courseId) {
      const response = await aulaService.getStudentCsvByCourseId(
        parseInt(courseId)
      );
      const csvData = new Blob([response.data], {
        type: "text/csv;charset=utf-8;",
      });
      const csvURL = window.URL.createObjectURL(csvData);
      const tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "estudiantes.csv");
      document.body.appendChild(tempLink);
      tempLink.click();
    }
  };

  const addUser = async () => {
    if (!form.validate().hasErrors && courseId) {
      setLoading(true);
      try {
        await aulaService.enroll(
          form.values.name,
          form.values.email,
          form.values.document_id,
          form.values.job,
          form.values.area,
          form.values.workplace,
          courseId
        );

        showNotification({
          title: "Estudiante añadido",
          message: `${form.values.name} ha sido añadido al curso.`,
          color: "green",
        });
      } catch {
        showNotification({
          title: "Error",
          message: "No se pudo añadir al estudiante.",
          color: "red",
        });
      } finally {
        setLoading(false);
        close();
        form.reset();
        setPage(1);
        getEnrollmentsData();
      }
    }
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "/aula-virtual", title: "Aula Virtual" }]}
      />

      {!courseData || !enrollmentsPage ? (
        <GenderLabLoader />
      ) : (
        <>
          <Card py="xs" radius="md" withBorder>
            <Text fz={26} align="center">
              {courseData.course.name}
            </Text>

            <Card.Section>
              <Divider my="sm" />
            </Card.Section>

            <Flex
              justify="space-evenly"
              align="center"
              direction={{ base: "column", sm: "row" }}
              gap="xs"
              mt="md"
            >
              <Flex direction="column" align="center">
                <Text fz={24} sx={{ lineHeight: 1 }}>
                  Marlene Molero
                </Text>
                <Text fz={14} color="dimmed">
                  docente
                </Text>
              </Flex>
              <Flex direction="column" align="center">
                <Text fz={24} sx={{ lineHeight: 1 }}>
                  {formatDate(courseData.course.release_date || "2023-07-26")}
                </Text>
                <Text fz={14} color="dimmed">
                  fecha de publicación del curso
                </Text>
              </Flex>
              <Flex gap="xs" align="center">
                <Flex direction="column" align="center">
                  <Text fz={24} sx={{ lineHeight: 1 }}>
                    {courseData.progress.completed}/{courseData.progress.total}
                  </Text>
                  <Text fz={14} color="dimmed">
                    terminaron el curso
                  </Text>
                </Flex>
                <RingProgress
                  size={60}
                  thickness={6}
                  sections={[
                    {
                      value: courseProgress,
                      color: "blue",
                    },
                  ]}
                  label={
                    <Text size="sm" align="center">
                      {courseProgress}%
                    </Text>
                  }
                />
              </Flex>
            </Flex>

            <Card.Section>
              <Divider my="md" />
            </Card.Section>

            <p style={{ textAlign: "justify" }}>
              {courseData.course.description ||
                "El curso no cuenta con una descripción."}
            </p>
          </Card>

          <Modal
            opened={opened}
            onClose={() => {
              form.reset();
              close();
            }}
            title={
              <Title
                sx={{
                  fontSize: 24,
                  "@media (max-width: 1024px)": {
                    fontSize: 18,
                  },
                }}
              >
                Añadir estudiante
              </Title>
            }
            zIndex={ZIndex.modal}
          >
            <Stack spacing="md">
              <TextInput placeholder="Nombre" {...form.getInputProps("name")} />
              <TextInput placeholder="Email" {...form.getInputProps("email")} />
              <TextInput
                placeholder="Doc. Identidad"
                {...form.getInputProps("document_id")}
              />
              <TextInput
                placeholder="Centro"
                {...form.getInputProps("workplace")}
              />
              <TextInput placeholder="Área" {...form.getInputProps("area")} />
              <TextInput placeholder="Puesto" {...form.getInputProps("job")} />
              <Button onClick={addUser} disabled={loading}>
                {loading ? <Loader size="xs" /> : "Añadir"}
              </Button>
            </Stack>
          </Modal>

          <ResultadosCard title="Estudiantes" sx={{ marginTop: 18 }}>
            <Flex gap="md">
              <TextInput
                placeholder="Buscar por nombre, documento, email, centro, área o puesto."
                w={450}
                value={query}
                onChange={(event) => setQuery(event.currentTarget.value)}
              />
              <Button
                color="green"
                variant="outline"
                onClick={open}
                disabled={user?.hasExpiredAULA}
              >
                + estudiante
              </Button>
            </Flex>
            <Table
              striped
              highlightOnHover
              withBorder
              withColumnBorders
              mt="md"
            >
              <thead>
                <tr>
                  <th onClick={() => handleSort("student__name")}>
                    <div className={classes.alumnosTHead}>
                      <>Nombre</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__document_id")}
                    className={classes.hideOnSmall}
                  >
                    <div className={classes.alumnosTHead}>
                      <>Doc. Identidad</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__email")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>Email</>
                      <FaSort />
                    </div>
                  </th>
                  <th onClick={() => handleSort("percentage_completed")}>
                    <div className={classes.alumnosTHead}>
                      <>Progreso</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__workplace")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>Centro</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__area")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>Área</>
                      <FaSort />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort("student__job")}
                    className={classes.hideOnMedium}
                  >
                    <div className={classes.alumnosTHead}>
                      <>Puesto</>
                      <FaSort />
                    </div>
                  </th>
                  <th>Certificado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {enrollmentsPage.data.map((row) => (
                  <tr key={row.id}>
                    <td>{row.student.name}</td>
                    <td className={classes.hideOnSmall}>
                      {row.student.document_id || "-"}
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.email}
                    </td>
                    <td>
                      {row.percentage_completed
                        ? Math.round(row.percentage_completed)
                        : 0}
                      %
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.workplace || "-"}
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.area || "-"}
                    </td>
                    <td className={classes.hideOnMedium}>
                      {row.student.job || "-"}
                    </td>
                    <td>
                      <Center>
                        <Button
                          compact
                          variant="outline"
                          color="blue"
                          disabled={row.percentage_completed < 100}
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/certificado-alumno-aula/${row.student.id}/${courseData.course.id}`,
                              "_blank"
                            )
                          }
                        >
                          Ver
                        </Button>
                      </Center>
                    </td>
                    <td>
                      <Center>
                        <Button
                          disabled={loading || user?.hasExpiredAULA}
                          compact
                          onClick={() => {
                            try {
                              openConfirmModal({
                                title: `Eliminar a ${row.student.name} del curso`,
                                labels: {
                                  cancel: "Cancelar",
                                  confirm: "Eliminar",
                                },
                                onConfirm: async () => {
                                  setLoading(true);
                                  if (courseId) {
                                    await aulaService.unenroll(
                                      row.student.id,
                                      parseInt(courseId)
                                    );
                                  }
                                  setPage(1);
                                  getEnrollmentsData();
                                  showNotification({
                                    title: "Estudiante eliminado",
                                    message: `${row.student.name} ha sido eliminado del curso.`,
                                    color: "green",
                                  });
                                  setEnrollmentsPage((prevState) => ({
                                    ...prevState,
                                    data: prevState.data.filter(
                                      (enrollment) =>
                                        enrollment.student.id !== row.student.id
                                    ),
                                  }));
                                  setLoading(false);
                                },
                              });
                            } catch {
                              showNotification({
                                title: "Error",
                                message: "No se pudo eliminar al estudiante.",
                                color: "red",
                              });
                            }
                          }}
                        >
                          <AiOutlineDelete />
                        </Button>
                      </Center>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Center>
              <Pagination
                mt="md"
                total={enrollmentsPage.total_pages}
                onChange={setPage}
                color="blue"
                page={activePage}
              />
            </Center>
          </ResultadosCard>

          <Group mt="md">
            <Button
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={courseData.course.syllabus_url}
              size="sm"
              color="blue"
              disabled={!courseData.course.syllabus_url}
            >
              Ver sílabo
            </Button>
            <Button color="blue" onClick={downloadStudents}>
              Descargar reporte
            </Button>
          </Group>
        </>
      )}
    </GenderLabAppShell>
  );
};
