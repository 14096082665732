import Chart from "react-apexcharts";
import { pieChartColors } from "../../utils";

type Props = {
  labels: string[];
  series: number[];
  height?: number;
  width?: number;
  fontSize?: number;
  offset?: number;
  legendPosition?: "top" | "bottom" | "left" | "right";
};

export const BenchmarkDistribucionPieChart = ({
  labels,
  series,
  height = undefined,
  width = undefined,
  fontSize = 12,
  offset = 0,
  legendPosition = "bottom",
}: Props) => {
  return (
    <Chart
      type="pie"
      options={{
        labels: labels,
        legend: {
          position: legendPosition,
          fontSize: `${fontSize}px`,
        },
        chart: {
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: offset,
            },
          },
        },
        dataLabels: {
          style: {
            fontSize: `${fontSize}px`,
          },
          dropShadow: {
            enabled: false,
          },
        },
        colors: pieChartColors,
      }}
      series={series}
      height={height}
      width={width}
    />
  );
};
