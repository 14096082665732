import { Grid, SimpleGrid } from "@mantine/core";
import FeatureCard from "./FeatureCard";
import { IconHome2 } from "@tabler/icons";

export const CardsInicio = (): JSX.Element => {
  return (
    <Grid.Col span={12}>
      <SimpleGrid
        cols={3}
        spacing="xl"
        verticalSpacing="xl"
        breakpoints={[
          { maxWidth: 1130, cols: 2 },
          { maxWidth: 755, cols: 1 },
        ]}
      >
        <FeatureCard
          title="Benchmark ELSA"
          description="El benchmark ELSA es una herramienta que te permitirá conocer la problemática de acoso sexual laboral en la región, revisa de forma detallada los principales hallazgos encontrados en la encuesta ELSA."
          redirectTo="/benchmark"
          buttonText="Ver el benchmark ELSA"
          badgeColor="blue"
          badgeText="Nuevo"
          imageUrl="img/banners/benchmarking-rojo.png"
        />
        <FeatureCard
          title="Plan de Acción"
          description="Accede a recomendaciones personalizadas según tus resultados. Estas recomendaciones están basasas en prácticas exitosas que te permitirán establecer una estrategia de prevención focalizada y efectiva."
          redirectTo="/plan-de-accion"
          buttonText="Revisar mi plan de acción"
          imageUrl="img/banners/plan-accion-celeste.png"
          badgeColor="blue"
          badgeText="Beta"
        />
        <FeatureCard
          title="Aula Virtual"
          description="Consulta los cursos disponibles que tenemos para ofrecerte, inscribe a tu personal, realiza un seguimiento de su progreso en el aula virtual ELSA y descarga los certificados de finalización."
          redirectTo="/aula-virtual"
          buttonText="Ir al aula virtual"
          imageUrl="img/banners/aula-morado.png"
        />
      </SimpleGrid>
    </Grid.Col>
  );
};
