import { Grid } from "@mantine/core";
import { CardsInicio } from "./CardsInicio";
import { BienvenidaInicio, BienvenidaInicioProps } from "./BienvenidaInicio";
import { PostsInicio } from "./PostsInicio";

export const DashboardInicio = ({
  tradeName,
}: BienvenidaInicioProps): JSX.Element => {  
  return (
    <Grid>
      <BienvenidaInicio tradeName={tradeName} />
      <CardsInicio />
      <PostsInicio />
    </Grid>
  );
};

export default DashboardInicio;
