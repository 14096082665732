import { Flex, Grid, Paper, Tabs, Text, Timeline, Title } from "@mantine/core";
import { IconPhoto, IconMessageCircle, IconSettings } from "@tabler/icons";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  CustomLink,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
} from "../../../components";
import { useAuth, useMedicionActual } from "../../../context";
import { clientService } from "../../../api";
import { TMedicionActualSetup, TMonitoreaData } from "../../../types";
import { OrganizationalSurverStatusIcon } from "./_organizationalSurverStatusIcon";
import { Monitorea } from "./_monitorea";
import { notificacionCambiosGuardadosCorrectamente } from "../../../utils";

export const MedicionPage = () => {
  const { authCallbackOnPageLoad, loading } = useAuth();
  const { medicionActualCookie, setMedicionActualCookie } = useMedicionActual();

  const { code } = useParams();
  const navigate = useNavigate();

  const [medicionActualSetup, setMedicionActualSetup] =
    useState<TMedicionActualSetup>(null!);
  const [monitoreaData, setMonitoreaData] = useState<TMonitoreaData>(null!);
  const [encuestaAlPersonalActiveState, setEncuestaAlPersonalActiveState] =
    useState<number>(-1);

  const [personalizarDisabled, setPersonalizarDisabled] = useState(true);
  const [previsualizarDisabled, setPrevisualizarDisabled] = useState(true);
  const [generarLinkDisabled, setGenerarLinkDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState<string | null>("");
  const [revisaDisabled, setRevisaDisabled] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (
      medicionActualCookie &&
      medicionActualCookie.staff_survey.status === "LAUNCHED"
    ) {
      setActiveTab("monitorea");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicionActualCookie]);

  useEffect(() => {
    if (
      monitoreaData &&
      medicionActualSetup &&
      monitoreaData.staff_survey.ended_at
    ) {
      const currentDate = new Date();
      const endedAtDate = new Date(monitoreaData.staff_survey.ended_at);
      endedAtDate.setHours(endedAtDate.getHours() + 29); // transformar a GMT - 0500
      if (
        medicionActualSetup.staff_survey.status === "LAUNCHED" &&
        currentDate.getTime() > endedAtDate.getTime()
      ) {
        setActiveTab("revisa");
        setRevisaDisabled(false);
      }
    }
  }, [monitoreaData, medicionActualSetup]);

  useEffect(() => {
    if (medicionActualSetup && medicionActualCookie) {
      setMedicionActualCookie({
        ...medicionActualCookie,
        staff_survey: {
          ...medicionActualCookie.staff_survey,
          status: medicionActualSetup.staff_survey.status,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicionActualSetup]);

  useEffect(() => {
    if (!medicionActualSetup) {
      authCallbackOnPageLoad(() => {
        clientService.measurements.getByCode(code).then((res) => {
          setMedicionActualSetup(res.data);
        });
      });
    }
    if (!monitoreaData) {
      authCallbackOnPageLoad(() => {
        clientService.measurements.getMonitoreaData(code).then((res) => {
          setMonitoreaData(res.data);
        });
      });
    }
  }, [authCallbackOnPageLoad, code, medicionActualSetup, monitoreaData]);

  useEffect(() => {
    if (location.state?.from) {
      notificacionCambiosGuardadosCorrectamente();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (medicionActualSetup) {
      setActiveTab("prepara");
      if (medicionActualSetup.staff_survey.completed_distribution) {
        setEncuestaAlPersonalActiveState(0);
        setPersonalizarDisabled(false);
      }
      if (medicionActualSetup.staff_survey.completed_personalization) {
        setEncuestaAlPersonalActiveState(1);
        setPrevisualizarDisabled(false);
      }
      if (medicionActualSetup.staff_survey.status === "PREVIEWED") {
        setEncuestaAlPersonalActiveState(2);
        setGenerarLinkDisabled(false);
      }
      if (medicionActualSetup.staff_survey.status === "LAUNCHED") {
        setEncuestaAlPersonalActiveState(3);
        setGenerarLinkDisabled(false);
        setActiveTab("monitorea");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicionActualSetup]);

  const previsualizar = () => {
    try {
      clientService.measurements.previewStaffSurvey(code).then((res) => {
        window.open(res.data.url, "_blank");
        navigate(0);
      });
    } catch (e) {
      showNotification({
        title: "Error",
        message: "No se pudo previsualizar la encuesta al personal",
      });
    }
  };

  const completedOrganizationalSurvey = () =>
    medicionActualSetup.organizational_survey.completed_policy_section &&
    medicionActualSetup.organizational_survey.completed_channels_section &&
    medicionActualSetup.organizational_survey.completed_training_section &&
    medicionActualSetup.organizational_survey.completed_good_practices_section;

  const generarEnlace = () => {
    if (medicionActualSetup.staff_survey.status === "LAUNCHED") {
      setActiveTab("monitorea");
    } else {
      if (completedOrganizationalSurvey()) {
        openConfirmModal({
          title: (
            <Text color="genderlab-red.6" size="xl" fw={700}>
              ¡Estás un paso de generar tu enlace!
            </Text>
          ),
          children: (
            <Text size="sm" color="dimmed">
              Una vez que generes tu enlace, ya no podrás hacer ningún tipo de
              cambio en esta medición. Por favor, asegúrate de haber
              previsualizado tu encuesta en la sección{" "}
              <strong>
                <u>Previsualizar</u>
              </strong>
              , así como de haber ingresado correctamente los datos en la
              sección{" "}
              <strong>
                <u>Personalizar</u>
              </strong>
              . En caso quieras modificar estos datos, puedes regresar. ¿Deseas
              generar el enlace de tu encuesta al personal?
            </Text>
          ),
          labels: {
            confirm: "Sí",
            cancel: "Atŕás",
          },
          onConfirm: async () => {
            try {
              clientService.measurements.launchStaffSurvey(code).then(() => {
                navigate(0);
              });
            } catch (error) {
              showNotification({
                title: "Error",
                message:
                  "No se pudo generar el enlace de la encuesta al personal.",
              });
            }
          },
        });
      } else {
        showNotification({
          title: "Error",
          message:
            "No se puede generar el enlace de la encuesta al personal. Primero debes completar la encuesta organizacional.",
        });
      }
    }
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/mediciones", title: "Mediciones" },
          { link: "", title: medicionActualCookie?.title || "" },
        ]}
      />
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List grow>
          <Tabs.Tab value="prepara" icon={<IconSettings size="1rem" />}>
            1. Prepara
          </Tabs.Tab>
          <Tabs.Tab
            value="monitorea"
            icon={<IconMessageCircle size="1rem" />}
            disabled={medicionActualSetup?.staff_survey.status !== "LAUNCHED"}
          >
            2. Monitorea
          </Tabs.Tab>
          <Tabs.Tab
            value="revisa"
            icon={<IconPhoto size="1rem" />}
            disabled={revisaDisabled}
          >
            3. Revisa
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="prepara">
          <Grid mt="xl">
            <Grid.Col span={12}>
              <Paper shadow="sm" p="xl" radius="lg">
                <Text>
                  La medición ELSA utiliza dos fuentes de información: una
                  encuesta organizacional y una encuesta al personal. En esta
                  pestaña, podrás compartir las prácticas y esfuerzos de tu
                  organización para prevenir el acoso sexual laboral y
                  personalizar la encuesta anónima que le enviarás a tu
                  personal. Podrás completar ambas secciones en simultáneo pero
                  tendrás que haber completado todos los pasos para poder
                  generar el enlace final.
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col span={12}>
              <Title order={3}>Encuesta organizacional</Title>
            </Grid.Col>
            <Grid.Col span={12}>
              <Flex direction="column" gap="sm">
                <Flex gap="md" align="center">
                  <OrganizationalSurverStatusIcon
                    loading={loading}
                    medicionActual={medicionActualSetup}
                    section="completed_policy_section"
                  />
                  <CustomLink
                    to={`/medicion/${code}/encuesta-organizacional/politicas`}
                  >
                    Políticas
                  </CustomLink>
                </Flex>
                <Flex gap="md" align="center">
                  <OrganizationalSurverStatusIcon
                    loading={loading}
                    medicionActual={medicionActualSetup}
                    section="completed_channels_section"
                  />
                  <CustomLink
                    to={`/medicion/${code}/encuesta-organizacional/canal-de-denuncias`}
                  >
                    Canal de denuncia
                  </CustomLink>
                </Flex>
                <Flex gap="md" align="center">
                  <OrganizationalSurverStatusIcon
                    loading={loading}
                    medicionActual={medicionActualSetup}
                    section="completed_training_section"
                  />
                  <CustomLink
                    to={`/medicion/${code}/encuesta-organizacional/capacitaciones`}
                  >
                    Capacitaciones
                  </CustomLink>
                </Flex>
                <Flex gap="md" align="center">
                  <OrganizationalSurverStatusIcon
                    loading={loading}
                    medicionActual={medicionActualSetup}
                    section="completed_good_practices_section"
                  />
                  <CustomLink
                    to={`/medicion/${code}/encuesta-organizacional/buenas-practicas`}
                  >
                    Buenas prácticas
                  </CustomLink>
                </Flex>
              </Flex>
            </Grid.Col>
          </Grid>

          <Grid mt="xl">
            <Grid.Col span={12}>
              <Title order={3}>Encuesta al personal</Title>
            </Grid.Col>
            <Grid.Col span={12}>
              <Timeline active={encuestaAlPersonalActiveState}>
                <Timeline.Item
                  title={
                    <CustomLink to="distribucion">Distribución</CustomLink>
                  }
                  color="green"
                >
                  <Text color="dimmed" size="xs">
                    Llena la tabla de distribución de tu personal desagregada
                    por género.
                  </Text>
                </Timeline.Item>
                <Timeline.Item
                  title={
                    <CustomLink
                      to="personalizacion"
                      disabled={personalizarDisabled}
                    >
                      Personalizar
                    </CustomLink>
                  }
                  color="green"
                >
                  <Text color="dimmed" size="xs">
                    Customiza las alternativas de algunas preguntas de tu
                    encuesta al personal.
                  </Text>
                </Timeline.Item>
                <Timeline.Item
                  title={
                    <CustomLink
                      to=""
                      disabled={previsualizarDisabled}
                      onClick={previsualizar}
                    >
                      Previsualizar
                    </CustomLink>
                  }
                  color="green"
                >
                  <Text color="dimmed" size="xs">
                    Observa cómo se verá tu encuesta al personal antes de
                    enviarla y revisa tus customizaciones.
                  </Text>
                </Timeline.Item>
                <Timeline.Item
                  title={
                    <CustomLink
                      to=""
                      disabled={generarLinkDisabled}
                      onClick={generarEnlace}
                    >
                      Generar enlace
                    </CustomLink>
                  }
                  color="green"
                >
                  <Text color="dimmed" size="xs">
                    Obtén el link de tu encuesta al personal y envíalo para
                    empezar el proceso de recojo de información.
                  </Text>
                </Timeline.Item>
              </Timeline>
            </Grid.Col>
          </Grid>
        </Tabs.Panel>

        <Tabs.Panel value="monitorea">
          <Monitorea monitoreaData={monitoreaData} />
        </Tabs.Panel>

        <Tabs.Panel value="revisa">
          <Paper shadow="sm" p="xl" radius="lg" mt="xl">
            ¡Felicitaciones por terminar la encuesta! Ya puedes ver el resumen
            de tus resultados{" "}
            <CustomLink to={`/tus-resultados/${code}/index`} fontSize="16px">
              AQUÍ
            </CustomLink>
            .
          </Paper>
        </Tabs.Panel>
      </Tabs>
    </GenderLabAppShell>
  );
};
