import { Heatmap, ResultadosCard } from "../../components";
import { parsePercentage } from "../../utils";
import { ToleranciaGrid } from "../Grids/ToleranciaGrid";
import { GeneralBenchmarkProps } from "./GeneralBenchmarkProps";

export const BenchmarkToleranciaHeatmap = ({
  generalBenchmark,
}: GeneralBenchmarkProps): JSX.Element => {
  return (
    <ResultadosCard
      title="Tolerancia frente al hostigamiento o acoso laboral"
      description="Porcentaje de personas encuestadas de las empresas ELSA que no le ven nada de malo a estas situaciones o que les parecen inapropiadas para el trabajo pero no las identifican como acoso sexual."
    >
      <Heatmap
        categories={[
          "T1",
          "T2",
          "T3",
          "T4",
          "T5",
          "T6",
          "T7",
          "T8",
          "T9",
          "T10",
          "T11",
          "T12",
        ]}
        data={[
          {
            name: "Total",
            data: [
              parsePercentage(generalBenchmark.benchmarks.RB_0095),
              parsePercentage(generalBenchmark.benchmarks.RB_0096),
              parsePercentage(generalBenchmark.benchmarks.RB_0097),
              parsePercentage(generalBenchmark.benchmarks.RB_0098),
              parsePercentage(generalBenchmark.benchmarks.RB_0099),
              parsePercentage(generalBenchmark.benchmarks.RB_0100),
              parsePercentage(generalBenchmark.benchmarks.RB_0101),
              parsePercentage(generalBenchmark.benchmarks.RB_0102),
              parsePercentage(generalBenchmark.benchmarks.RB_0103),
              parsePercentage(generalBenchmark.benchmarks.RB_0104),
              parsePercentage(generalBenchmark.benchmarks.RB_0105),
              parsePercentage(generalBenchmark.benchmarks.RB_0106),
            ],
          },
          {
            name: "No binario",
            data: [
              parsePercentage(generalBenchmark.benchmarks.RB_0131),
              parsePercentage(generalBenchmark.benchmarks.RB_0132),
              parsePercentage(generalBenchmark.benchmarks.RB_0133),
              parsePercentage(generalBenchmark.benchmarks.RB_0134),
              parsePercentage(generalBenchmark.benchmarks.RB_0135),
              parsePercentage(generalBenchmark.benchmarks.RB_0136),
              parsePercentage(generalBenchmark.benchmarks.RB_0137),
              parsePercentage(generalBenchmark.benchmarks.RB_0138),
              parsePercentage(generalBenchmark.benchmarks.RB_0139),
              parsePercentage(generalBenchmark.benchmarks.RB_0140),
              parsePercentage(generalBenchmark.benchmarks.RB_0141),
              parsePercentage(generalBenchmark.benchmarks.RB_0142),
            ],
          },
          {
            name: "Femenino",
            data: [
              parsePercentage(generalBenchmark.benchmarks.RB_0119),
              parsePercentage(generalBenchmark.benchmarks.RB_0120),
              parsePercentage(generalBenchmark.benchmarks.RB_0121),
              parsePercentage(generalBenchmark.benchmarks.RB_0122),
              parsePercentage(generalBenchmark.benchmarks.RB_0123),
              parsePercentage(generalBenchmark.benchmarks.RB_0124),
              parsePercentage(generalBenchmark.benchmarks.RB_0125),
              parsePercentage(generalBenchmark.benchmarks.RB_0126),
              parsePercentage(generalBenchmark.benchmarks.RB_0127),
              parsePercentage(generalBenchmark.benchmarks.RB_0128),
              parsePercentage(generalBenchmark.benchmarks.RB_0129),
              parsePercentage(generalBenchmark.benchmarks.RB_0130),
            ],
          },
          {
            name: "Masculino",
            data: [
              parsePercentage(generalBenchmark.benchmarks.RB_0107),
              parsePercentage(generalBenchmark.benchmarks.RB_0108),
              parsePercentage(generalBenchmark.benchmarks.RB_0109),
              parsePercentage(generalBenchmark.benchmarks.RB_0110),
              parsePercentage(generalBenchmark.benchmarks.RB_0111),
              parsePercentage(generalBenchmark.benchmarks.RB_0112),
              parsePercentage(generalBenchmark.benchmarks.RB_0113),
              parsePercentage(generalBenchmark.benchmarks.RB_0114),
              parsePercentage(generalBenchmark.benchmarks.RB_0115),
              parsePercentage(generalBenchmark.benchmarks.RB_0116),
              parsePercentage(generalBenchmark.benchmarks.RB_0117),
              parsePercentage(generalBenchmark.benchmarks.RB_0118),
            ],
          },
        ]}
      />
      {ToleranciaGrid()}
    </ResultadosCard>
  );
};
