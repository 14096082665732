import { BarChart } from "../Charts";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithResponsiveStyleProps } from "./GeneralBenchmarkProps";

export const BenchmarkReaccionesCard = ({
    generalBenchmark,
    responsiveStyle
}: GeneralBenchmarkWithResponsiveStyleProps): JSX.Element => {
    return (
        <ResultadosCard
            title="Reacciones frente al hostigamiento o acoso sexual laboral"
            description="Reacciones que tuvieron las personas que reconocen expresamente haber vivido acoso sexual."
        >
            <BarChart
                height={500}
                width={650}
                responsive={responsiveStyle}
                categories={[
                    ["Evité y me alejé", "de la persona"],
                    [
                        "Confronté a la persona",
                        "que estaba haciendo",
                        "estas conductas.",
                    ],
                    ["Se lo conté a", "un compañero"],
                    ["No comenté lo", "sucedido con nadie", "de la organización"],
                    ["Hablé con mi", "jefe directo"],
                    [
                        "Pedí que me cambien",
                        "de lugar de trabajo",
                        "con otra excusa",
                    ],
                    ["Lo denuncié y seguí", "el proceso de", "investigación"],
                    ["Hablé con recursos", "humanos pero decidí", "no denunciar"],
                ]}
                seriesNames={["Porcentaje (%)"]}
                seriesData={[
                    [
                        generalBenchmark.benchmarks.RB_0143,
                        generalBenchmark.benchmarks.RB_0144,
                        generalBenchmark.benchmarks.RB_0145,
                        generalBenchmark.benchmarks.RB_0146,
                        generalBenchmark.benchmarks.RB_0147,
                        generalBenchmark.benchmarks.RB_0148,
                        generalBenchmark.benchmarks.RB_0149,
                        generalBenchmark.benchmarks.RB_0150,
                    ],
                ]}
                max={100}
            />
        </ResultadosCard>
    );
}