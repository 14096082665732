import { Grid, Text } from "@mantine/core";

export const ToleranciaGrid = (): JSX.Element => {
    return (
        <Grid gutter="md">
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T1: Hacer comentarios o bromas de contenido sexual o sexista.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T2: Hacer comentarios o bromas sobre la orientación sexual o identidad
              de género de una persona.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T3: Hacer comentarios sobre la apariencia física o cuerpo de una
              persona que generan incomodidad, incluyendo también gestos, silbidos o
              sonidos.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T4: Invitar reiteradamente a salir a una persona a pesar de no recibir
              respuesta o no recibir respuesta positiva.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T5: Contactar reiteradamente a través de mensajes electrónicos, chats,
              WhatsApp, Facebook, entre otros, con fines no laborales que generan
              incomodidad.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T6: Solicitar o enviar sin consentimiento fotos o videos de material
              sexual explícito o de connotación sexual.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T7: Acercarse demasiado físicamente o tocar (rozar, sobar, masajear,
              etc.) sin necesidad a una persona en alguna parte del cuerpo como
              hombros, espalda baja, rodilla u otras partes.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T8: Tocar o rozar a una persona en zonas privadas del cuerpo como los
              senos, genitales, etc.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T9: Mirar persistentemente con connotación sexual a una persona.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T10: Que un jefe/a se insinúe y/o realice invitaciones a salir a pesar
              que de alguna manera se le mostró incomodidad.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T11: Insinuar o proponer mejoras en la situación laboral de una
              persona a cambio de aceptar invitaciones, acercamientos o encuentros
              sexuales.
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text color="dimmed" size="xs">
              T12: Amenazar o insinuar con despedir a una persona, retirarle los
              beneficios laborales o tomar alguna represalia por no aceptar
              invitaciones, acercamientos, o encuentros sexuales.
            </Text>
          </Grid.Col>
        </Grid>
    );
}