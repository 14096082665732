import { Group, Select, Text, Loader } from "@mantine/core";
import { forwardRef, useEffect, useState } from "react";
import { TClientAdmin } from "../../types";
import { adminService } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";

type ItemData = {
  label: string;
  value: string;
};

const SelectItem = forwardRef<HTMLDivElement, ItemData>(
  ({ label, value, ...others }: ItemData, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  )
);

type Props = {
  access_role: string;
};

export const ClientSelector = ({ access_role }: Props) => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<ItemData[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const getClientList = async () => {
      setLoading(true);
      try {
        const response =
          access_role === "partner"
            ? await adminService.partner.getClientList()
            : await adminService.superuser.getClientList();
        const rawClients: TClientAdmin[] = response.data;
        rawClients.sort((a, b) => {
          if (a.trade_name < b.trade_name) return -1;
          if (a.trade_name > b.trade_name) return 1;
          return 0;
        });

        const _clients = rawClients.map((client) => ({
          image: client.logo,
          label: client.trade_name,
          value: client.id.toString(),
        }));
        setClients(_clients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
      setLoading(false);
    };
    getClientList();
  }, [access_role]);

  useEffect(() => {
    if (user) {
      setSelectedClient(user.clientId.toString());
    }
  }, [user]);

  const onChange = async (value: string) => {
    setLoading(true);
    try {
      const response =
        access_role === "partner"
          ? await adminService.partner.updateAssignedClient(value)
          : await adminService.superuser.updateAssignedClient(value);
      if (response.status === 200) {
        navigate(0);
      } else {
        throw new Error("Error updating client");
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error updating client:", error.message);
      } else {
        console.error("Unknown error occurred while updating client");
      }
    }
    setLoading(false);
  };

  return (
    <Select
      disabled={clients.length === 0}
      itemComponent={SelectItem}
      data={clients}
      placeholder="Selecciona un cliente"
      searchable
      maxDropdownHeight={300}
      nothingFound="No se encontraron clientes"
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase()) || false
      }
      value={selectedClient ?? undefined}
      onChange={onChange}
      rightSection={loading ? <Loader color="gray" size="xs" /> : null}
    />
  );
};

export default ClientSelector;
