import { ResultadosCard } from "../Resultados";
import { BenchmarkDistribucionPieChart } from "./BenchmarkDistribucionPieChart";

type BenchmarkMuestraGeneroProps = {
    title: string;
    labels: string[];
    series: number[];
};

export const BenchmarkDistribucionPieChartCard = ({
    title,
    labels,
    series,
}: BenchmarkMuestraGeneroProps) => {
  return (
    <ResultadosCard title={title}>
        <BenchmarkDistribucionPieChart
            labels={labels}
            series={series.map((serie) => parseFloat((serie * 100).toFixed(2)))}
            height={400}
            legendPosition="bottom"
        />
    </ResultadosCard>
  );
}