import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Image,
  Radio,
  Select,
  TextInput,
  Title,
  Text,
  Skeleton,
  Table,
  Paper,
  Tabs,
  Alert,
  Anchor,
} from "@mantine/core";
import { closeAllModals, openConfirmModal, openModal } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { format } from "date-fns";
import { BsInfoCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import {
  AddUserButton,
  ClientSelector,
  EliminarButton,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  LabelText,
  ImageCropper,
} from "../../components";
import { SelectItemWithIcon } from "../../components/SelectItemWithIcon/index";
import { linkColor, onChangeCleanFields } from "../../utils";
import { useAuth } from "../../context";
import { TUserClient } from "../../types";
import { tokenService, clientService } from "../../api";

const DEFAULT_LOGO =
  "https://genderlab-diagnosis-dev.s3.amazonaws.com/media/clients/default_logo.png";

type MyAccountData = {
  informacionDeLaOrganizacion: {
    nombreComercial: string;
    razonSocial: string;
    perteneceAGrupoEconomico: string;
    grupoEconomico: string;
    ubicacion: string;
    tipo: string;
    sectorEconomico: string;
  };
  informacionDeContacto: {
    nombres: string;
    apellidos: string;
    correo: string;
    codigoPais: string;
    telefono: string;
    cargo: string;
  };
  paises: {
    id: number;
    nombre: string;
    fotoUrl: string;
    codigoTelefonoPais: string;
  }[];
  tiposDeOrganizacion: {
    id: number;
    nombre: string;
  }[];
  sectoresEconomicos: {
    id: number;
    nombre: string;
  }[];
};

export const MiCuentaPage = () => {
  const { loading, setLoading, user, authRequest, setUser } = useAuth();
  const [myAccountData, setMyAccountData] = useState<MyAccountData | null>(
    null
  );
  const [selectedLogo, setSelectedLogo] = useState("");
  const [users, setUsers] = useState<TUserClient[]>([]);
  const access_role = tokenService.getUserFromToken()?.access_role;

  useEffect(() => {
    if (user) setSelectedLogo(user.logo);
  }, [user]);

  const getUsers = async () => {
    const response = await clientService.users.get();
    setUsers(response.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const navigate = useNavigate();

  const deleteLogo = async () => {
    openConfirmModal({
      title: "¿Estás seguro que deseas borrar tu logo?",
      children: "Esta acción no se puede deshacer.",
      labels: {
        confirm: "Borrar",
        cancel: "Cancelar",
      },
      onConfirm: async () => {
        setLoading(true);
        await authRequest(clientService.info.delete);
        setLoading(false);
        navigate(0);
      },
    });
  };

  const openImageModal = () =>
    openModal({
      title: "Subir logo",
      children: <ImageCropper callback={uploadImage} />,
    });

  const form = useForm({
    initialValues: {
      informacionDeLaOrganizacion: {
        nombreComercial: "",
        razonSocial: "",
        perteneceAGrupoEconomico: "",
        grupoEconomico: "",
        ubicacion: "",
        tipo: "",
        sectorEconomico: "",
      },
      informacionDeContacto: {
        nombres: "",
        apellidos: "",
        correo: "",
        codigoPais: "",
        telefono: "",
        cargo: "",
      },
    },

    validate: {
      informacionDeLaOrganizacion: {
        nombreComercial: (value) => !value && "Campo requerido",
        razonSocial: (value) => !value && "Campo requerido",
        perteneceAGrupoEconomico: (value) => !value && "Campo requerido",
        grupoEconomico: (value) => {
          if (
            form.values.informacionDeLaOrganizacion.perteneceAGrupoEconomico ===
            "si"
          ) {
            return value === "" && "Campo requerido";
          }
          return null;
        },
        ubicacion: (value) => !value && "Campo requerido",
        tipo: (value) => !value && "Campo requerido",
        sectorEconomico: (value) => !value && "Campo requerido",
      },
      informacionDeContacto: {
        nombres: (value) => !value && "Campo requerido",
        apellidos: (value) => !value && "Campo requerido",
        correo: (value) => !value && "Campo requerido",
        codigoPais: (value) => !value && "Campo requerido",
        telefono: (value) => !value && "Campo requerido",
        cargo: (value) => !value && "Campo requerido",
      },
    },
  });

  useEffect(() => {
    if (!myAccountData) {
      setLoading(true);
      clientService.profile.get().then((response) => {
        const data = response.data;
        const accData: MyAccountData = {
          informacionDeLaOrganizacion: {
            nombreComercial: data.profile.trade_name,
            razonSocial: data.profile.business_name,
            perteneceAGrupoEconomico: data.profile.is_in_economic_group
              ? "si"
              : "no",
            grupoEconomico: data.profile.economic_group_name,
            ubicacion: data.profile.country?.toString(),
            tipo: data.profile.company_type?.toString(),
            sectorEconomico: data.profile.economic_sector?.toString(),
          },
          informacionDeContacto: {
            nombres: data.profile.contact_name,
            apellidos: data.profile.contact_lastname,
            correo: data.profile.contact_email,
            codigoPais: data.profile.contact_phone_country?.toString(),
            telefono: data.profile.contact_phone,
            cargo: data.profile.contact_role,
          },
          paises: data.countries.map((c: any) => ({
            id: c.id,
            nombre: c.name,
            fotoUrl: c.flag_url,
            codigoTelefonoPais: c.phone_prefix,
          })),
          tiposDeOrganizacion: data.company_types.map((c: any) => ({
            id: c.id,
            nombre: c.name,
          })),
          sectoresEconomicos: data.economic_sectors.map((c: any) => ({
            id: c.id,
            nombre: c.name,
          })),
        };
        setMyAccountData(accData);
        form.setValues({
          informacionDeLaOrganizacion: accData.informacionDeLaOrganizacion,
          informacionDeContacto: accData.informacionDeContacto,
        });
        setLoading(false);
      });
    }
  }, [setLoading, myAccountData, form, authRequest]);

  const uploadImage = async (dataUrl: string) => {
    const imgResponse = await fetch(dataUrl);
    const buffer = await imgResponse.arrayBuffer();
    const imageType = dataUrl.match(/^data:image\/([a-z]+);base64,/);
    if (!imageType) throw new Error("Tipo de imagen inválido.");
    const file = new File([buffer], `logo.${imageType[1]}`, {
      type: `image/${imageType[1]}`,
    });
    const formData = new FormData();
    formData.append("logo", file);
    setLoading(true);
    await authRequest(clientService.info.put, formData);
    setLoading(false);
    navigate(0);
  };

  const acceptSavedProfile = () => {
    if (user) {
      navigate(0);
      if (user.hasMeasurementProcess) {
        navigate("/mediciones");
      } else if (user.hasAULA) {
        navigate("/aula-virtual");
      } else {
        navigate("/inicio");
      }
      const updatedUser = user;
      updatedUser.completedProfile = true;
      setUser(updatedUser);
      closeAllModals();
    } else {
      showNotification({
        title: "Error",
        message: "Error al guardar los datos",
        color: "red",
      });
    }
  };

  const saveProfile = async () => {
    if (form.validate().hasErrors) {
      showNotification({
        title: "Error",
        message: "Por favor, revise los campos marcados en rojo",
        color: "red",
      });
    } else {
      setLoading(true);
      try {
        await clientService.profile.put({
          contact_name: form.values.informacionDeContacto.nombres,
          contact_lastname: form.values.informacionDeContacto.apellidos,
          contact_role: form.values.informacionDeContacto.cargo,
          contact_email: form.values.informacionDeContacto.correo,
          contact_phone: form.values.informacionDeContacto.telefono,
          contact_phone_country: parseInt(
            form.values.informacionDeContacto.codigoPais
          ),
          trade_name: form.values.informacionDeLaOrganizacion.nombreComercial,
          business_name: form.values.informacionDeLaOrganizacion.razonSocial,
          is_in_economic_group:
            form.values.informacionDeLaOrganizacion.perteneceAGrupoEconomico ===
            "si",
          economic_group_name:
            form.values.informacionDeLaOrganizacion.grupoEconomico || null,
          company_type: parseInt(form.values.informacionDeLaOrganizacion.tipo),
          economic_sector: parseInt(
            form.values.informacionDeLaOrganizacion.sectorEconomico
          ),
          country: parseInt(form.values.informacionDeLaOrganizacion.ubicacion),
        });
        setLoading(false);
        openModal({
          title: "Datos guardados",
          children: (
            <>
              <Text size="sm">Los cambios han sido guardados con éxito.</Text>
              <Button fullWidth onClick={() => acceptSavedProfile()} mt="md">
                Aceptar
              </Button>
            </>
          ),
          onClose() {
            acceptSavedProfile();
          },
        });
      } catch {
        showNotification({
          title: "Error",
          message: "Error al guardar los datos",
          color: "red",
        });
        setLoading(false);
      }
    }
  };

  const deleteUser = async (id: number, email: string) => {
    openConfirmModal({
      title: <b>Eliminar a {email}</b>,
      children: "¿Estás seguro que deseas eliminar este usuario?",
      labels: {
        confirm: "Eliminar",
        cancel: "Cancelar",
      },
      onConfirm: async () => {
        setLoading(true);
        await clientService.users.delete(id);
        setLoading(false);
        setUsers(users.filter((u) => u.id !== id));
      },
    });
  };

  return (
    <GenderLabAppShell>
      <Flex gap="md" align="center">
        <GenderLabBreadcrumbs
          breadcrumbs={[{ link: "/mi-cuenta", title: "Mi cuenta" }]}
        />
        {(access_role === "superuser" || access_role === "partner") && (
          <ClientSelector access_role={access_role} />
        )}{" "}
      </Flex>

      <Alert color="yellow" icon={<BsInfoCircle />} title="Soporte" mb="md">
        <Text>
          Si necesitas ayuda o soporte técnico, por favor contáctanos a través
          de nuestro correo electrónico{" "}
          <Anchor
            href="mailto:support@elsa.la"
            sx={{
              color: linkColor,
              textDecoration: "underline",
            }}
          >
            support@elsa.la
          </Anchor>
          .
        </Text>
      </Alert>

      <Tabs defaultValue="perfil-de-la-org">
        <Tabs.List>
          <Tabs.Tab value="perfil-de-la-org">
            Perfil de la organización
          </Tabs.Tab>
          <Tabs.Tab value="usuarios">Usuarios</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="perfil-de-la-org">
          <form>
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={4}>Información de la organización</Title>
              </Grid.Col>
              <Grid.Col span={12}>
                <Center>
                  <LabelText>Logo de la organización</LabelText>
                </Center>
              </Grid.Col>
              {selectedLogo ? (
                <Grid.Col span={12}>
                  <Center style={{ position: "relative" }}>
                    <Image
                      src={selectedLogo}
                      alt="logo de la empresa"
                      width={250}
                      radius="md"
                    />
                  </Center>
                </Grid.Col>
              ) : (
                <Grid.Col span={12}>
                  <Center>
                    <Skeleton height={250} circle />
                  </Center>
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Center>
                  <Flex gap="xs">
                    <Button size="xs" color="green" onClick={openImageModal}>
                      <AiOutlineEdit color="white" />
                    </Button>
                    {user?.logo !== DEFAULT_LOGO && (
                      <Button
                        size="xs"
                        color="red"
                        onClick={deleteLogo}
                        disabled={loading}
                      >
                        <AiOutlineDelete color="white" />
                      </Button>
                    )}
                  </Flex>
                </Center>
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label="Nombre comercial"
                  disabled={loading}
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.nombreComercial"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label="Razón social"
                  disabled={loading}
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.razonSocial"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Select
                  label="Ubicación de la organización"
                  disabled={loading}
                  itemComponent={SelectItemWithIcon}
                  data={
                    myAccountData?.paises
                      .map((p) => ({
                        image: p.fotoUrl,
                        label: p.nombre,
                        value: p.id.toString(),
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label)) || []
                  }
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.ubicacion"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Select
                  label="Sector económico de la organización"
                  disabled={loading}
                  data={
                    myAccountData?.sectoresEconomicos.map((p) => ({
                      label: p.nombre,
                      value: p.id.toString(),
                    })) || []
                  }
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.sectorEconomico"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Select
                  label="Tipo de organización"
                  disabled={loading}
                  data={
                    myAccountData?.tiposDeOrganizacion.map((p) => ({
                      label: p.nombre,
                      value: p.id.toString(),
                    })) || []
                  }
                  {...form.getInputProps("informacionDeLaOrganizacion.tipo")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <Radio.Group
                  label="¿La organización pertenece a algún grupo económico?"
                  {...form.getInputProps(
                    "informacionDeLaOrganizacion.perteneceAGrupoEconomico"
                  )}
                  onChange={(value) =>
                    onChangeCleanFields(
                      value,
                      "informacionDeLaOrganizacion.perteneceAGrupoEconomico",
                      ["informacionDeLaOrganizacion.grupoEconomico"],
                      "no",
                      form
                    )
                  }
                >
                  <Radio value="si" label="Sí" />
                  <Radio value="no" label="No" />
                </Radio.Group>
              </Grid.Col>
              {form.values.informacionDeLaOrganizacion
                .perteneceAGrupoEconomico === "si" && (
                <Grid.Col span={12} md={6}>
                  <TextInput
                    disabled={
                      form.values.informacionDeLaOrganizacion
                        .perteneceAGrupoEconomico !== "si" || loading
                    }
                    label="Nombre del grupo económico"
                    {...form.getInputProps(
                      "informacionDeLaOrganizacion.grupoEconomico"
                    )}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
            <Grid mt="md">
              <Grid.Col span={12}>
                <Title order={4}>Información de contacto principal</Title>
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label="Nombres"
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.nombres")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label="Apellidos"
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.apellidos")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label="Correo electrónico"
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.correo")}
                />
              </Grid.Col>
              <Grid.Col span={3} md={1.5}>
                <Select
                  label="Cód. país"
                  disabled={loading}
                  itemComponent={SelectItemWithIcon}
                  data={
                    myAccountData?.paises
                      .map((p) => ({
                        image: p.fotoUrl,
                        label: p.codigoTelefonoPais,
                        value: p.id.toString(),
                      }))
                      .sort((a, b) => parseInt(a.label) - parseInt(b.label)) ||
                    []
                  }
                  {...form.getInputProps("informacionDeContacto.codigoPais")}
                />
              </Grid.Col>
              <Grid.Col span={9} md={4.5}>
                <TextInput
                  label="Teléfono"
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.telefono")}
                />
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                <TextInput
                  label="Cargo"
                  disabled={loading}
                  {...form.getInputProps("informacionDeContacto.cargo")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
            </Grid>
          </form>
          <Button onClick={saveProfile} disabled={loading} my="md">
            Guardar
          </Button>
        </Tabs.Panel>

        <Tabs.Panel value="usuarios">
          <Title order={4} my="md">
            Lista de usuarios
          </Title>
          <Paper my="md">
            <Table withBorder withColumnBorders striped>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Fecha de creación</th>
                  <th scope="col" style={{ width: "5%" }} />
                </tr>
              </thead>
              <tbody>
                {users.map((u) => (
                  <tr key={u.id}>
                    <td>
                      {u.first_name} {u.last_name}
                    </td>
                    <td>{u.email}</td>
                    <td>{format(u.created_at, "dd/MM/yyyy")}</td>
                    <td>
                      <Center>
                        <EliminarButton
                          onClick={() => deleteUser(u.id, u.email)}
                        />
                      </Center>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
          <Divider my="md" />
          <Flex gap="sm">
            <AddUserButton callback={async () => await getUsers()} />
          </Flex>
        </Tabs.Panel>
      </Tabs>
    </GenderLabAppShell>
  );
};
