import { Flex, Grid } from "@mantine/core";
import { ResultadosCard } from "../Resultados";
import { GeneralBenchmarkWithResponsiveStyleProps } from "./GeneralBenchmarkProps";
import { colorAzul1 } from "../../utils";
import { BarChart } from "../Charts";

export const BenchmarkPrevalenciaPerfilPersonal = ({
    generalBenchmark,
    responsiveStyle,
}: GeneralBenchmarkWithResponsiveStyleProps) => {
    return (
        <ResultadosCard
            title="Prevalencia según perfil personal"
            description="Nivel de incidencia de acoso sexual por cada grupo poblacional."
        >
            <Grid>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title="Prevalencia por género"
                        categories={[
                            "Total",
                            "Masculino",
                            "Femenino",
                            "No binario",
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0204,
                                generalBenchmark.benchmarks.RB_0205,
                                generalBenchmark.benchmarks.RB_0206,
                            ],
                        ]}
                        seriesNames={["Prevalencia (%)"]}
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title="Prevalencia por comunidad LGBTIQ+"
                        categories={[
                            "Total",
                            "Comunidad LGBTIQ+",
                            "No comunidad LGBTIQ+",
                            "Prefiero no decir",
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0207,
                                generalBenchmark.benchmarks.RB_0208,
                                generalBenchmark.benchmarks.RB_0209,
                            ],
                        ]}
                        seriesNames={["Prevalencia (%)"]}
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title="Prevalencia por rango etario"
                        categories={[
                            "Total",
                            "18 - 29 años",
                            "30 - 49 años",
                            "50 - 59 años",
                            "60 años a más",
                            "Prefiero no responder",
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0210,
                                generalBenchmark.benchmarks.RB_0211,
                                generalBenchmark.benchmarks.RB_0212,
                                generalBenchmark.benchmarks.RB_0213,
                                generalBenchmark.benchmarks.RB_0214,
                            ],
                        ]}
                        seriesNames={["Prevalencia (%)"]}
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
                <Grid.Col span={12} md={6}>
                    <BarChart
                        height={300}
                        width={450}
                        responsive={responsiveStyle}
                        title="Prevalencia por grupo étnico-racial"
                        categories={[
                            "Total",
                            "Asiático",
                            "Mestizo",
                            "Indígena",
                            "Afrodescendiente",
                            "Blanco",
                            "Otro",
                            "Prefiero no responder",
                        ]}
                        seriesData={[
                            [
                                generalBenchmark.benchmarks.RB_0040,
                                generalBenchmark.benchmarks.RB_0215,
                                generalBenchmark.benchmarks.RB_0216,
                                generalBenchmark.benchmarks.RB_0217,
                                generalBenchmark.benchmarks.RB_0218,
                                generalBenchmark.benchmarks.RB_0219,
                                generalBenchmark.benchmarks.RB_0220,
                                generalBenchmark.benchmarks.RB_0221,
                            ],
                        ]}
                        seriesNames={["Prevalencia (%)"]}
                        colors={[colorAzul1]}
                    />
                </Grid.Col>
            </Grid>
            <Flex
                direction={{ base: "column", xl: "row" }}
                gap="sm"
                align="center"
                justify="space-evenly"
            ></Flex>
        </ResultadosCard>
    );
}
