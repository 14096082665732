import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context";
import {
  Text,
  Button,
  Checkbox,
  Flex,
  Anchor,
  Title,
  Modal,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { closeAllModals, openModal } from "@mantine/modals";
import { useForm } from "@mantine/form";
import { clientService, tokenService } from "../api";

type Props = {
  children: React.ReactNode;
};

export const ProtectedRoute = ({ children }: Props) => {
  const { tryToPersistSession, user, setUser } = useAuth();
  const [loadingTerms, setLoadingTerms] = useState(false);
  const [openedModalTerms, setOpenedModalTerms] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const termsForm = useForm({
    initialValues: {
      privacy: false,
      terms: false,
    },
    validate: {
      privacy: (value: boolean) =>
        value === true ? null : "Debes aceptar la política de privacidad",
      terms: (value: boolean) =>
        value === true ? null : "Debes aceptar los términos y condiciones",
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = termsForm.validate();
    if (!validation.hasErrors) {
      setLoadingTerms(true);
      clientService.acceptTerms
        .post()
        .then(() => {
          const updatedUser = user;
          updatedUser!.termsAccepted = true;
          setUser(updatedUser);
          showNotification({
            title: "Términos aceptados",
            message: "Gracias por aceptar los términos y condiciones",
            color: "teal",
            icon: <IconCheck />,
          });
          setOpenedModalTerms(false);
          navigate("/inicio");
        })
        .catch((error) => {
          showNotification({
            title: "Error",
            message: error.message,
            color: "red",
            icon: <IconX />,
          });
        })
        .finally(() => {
          setLoadingTerms(false);
        });
    }
  };

  const preventClose = () => {
    showNotification({
      title: "Atención",
      message:
        "Debes aceptar los términos y condiciones para poder utilizar la plataforma ☝️.",
      color: "red",
    });
  };

  useEffect(() => {
    if (!user) tryToPersistSession();
    if (
      user &&
      tokenService.getUserFromToken()?.access_role === "client" &&
      !user.termsAccepted &&
      location.pathname !== "/terminos-y-condiciones"
    ) {
      setOpenedModalTerms(true);
    } else if (
      user &&
      tokenService.getUserFromToken()?.access_role === "client" &&
      !user.completedProfile &&
      location.pathname !== "/mi-cuenta"
    ) {
      openModal({
        title: "Atención",
        children: (
          <>
            <Text size="sm">
              Debe completar su cuenta antes de navegar por la aplicación.
            </Text>
            <Button
              fullWidth
              onClick={() => {
                navigate("/mi-cuenta");
                closeAllModals();
              }}
              mt="md"
            >
              Aceptar
            </Button>
          </>
        ),
        onClose() {
          navigate("/mi-cuenta");
        },
      });
    }
  }, [tryToPersistSession, navigate, location.pathname, user]);

  return (
    <>
      {children}
      <Modal
        opened={openedModalTerms}
        onClose={preventClose}
        closeOnClickOutside={false}
        title={<Title order={4}>Términos y condiciones</Title>}
        padding="xl"
      >
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="md">
            <Text size="sm" align="justify" sx={{ marginBottom: 10 }}>
              En ELSA, valoramos profundamente la seguridad y confidencialidad
              de la información de tu empresa. Nuestro compromiso es ofrecer un
              entorno seguro y fiable, diseñado para que tanto la organización
              como las personas puedan hacer uso de la plataforma con total
              confianza. Por ello, hemos implementado una serie de medidas y
              protocolos, los cuales se detallan en nuestra política de
              privacidad y términos y condiciones. Es necesario que leas y
              aceptes estos documentos para continuar.
            </Text>
            <Checkbox
              label={
                <span>
                  He leído y acepto la&nbsp;
                  <Anchor
                    href="http://www.elsa.la/politica-de-privacidad"
                    color="blue"
                    target="_blank"
                  >
                    política de privacidad
                  </Anchor>
                </span>
              }
              key="privacy"
              {...termsForm.getInputProps("privacy")}
            />
            <Checkbox
              label={
                <span>
                  He leído y acepto los&nbsp;
                  <Anchor
                    href="http://www.elsa.la/terminos-y-condiciones"
                    color="blue"
                    target="_blank"
                  >
                    términos y condiciones
                  </Anchor>
                </span>
              }
              key="terms"
              {...termsForm.getInputProps("terms")}
            />
            <Button fullWidth mt="md" type="submit" disabled={loadingTerms}>
              Confirmar
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};
