import { useParams } from "react-router-dom";
import { CertificadoShell, GenderLabLoader } from "../../components";
import { useEffect, useState } from "react";
import { aulaService } from "../../api";
import { TStudentCertificateData } from "../../types";

export const CertificadoAlumnoAulaPage = () => {
  const { estudiante, curso } = useParams();

  const [loading, setLoading] = useState(true);
  const [certificateData, setCertificateData] =
    useState<TStudentCertificateData | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        if (estudiante && curso) {
          const response = await aulaService.getStudentCertificateData(
            curso,
            estudiante
          );
          setCertificateData(response.data);
        }
      } catch (e) {
        console.error("Error al cargar el certificado", e);
      }
    };

    getData().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <GenderLabLoader />;
  }

  if (!certificateData) {
    return <div>Ha ocurrido un error al cargar el certificado.</div>;
  }

  return (
    <CertificadoShell
      nombreEmpresa={certificateData.student_name}
      nombreCurso={certificateData.client_trade_name}
      customHeader="certificado de participación otorgado a:"
      fecha={certificateData.completed_at}
    >
      Por haber completado al 100% el curso{" "}
      <b>{certificateData.course_title}</b> en el Aula Virtual de ELSA.
    </CertificadoShell>
  );
};
